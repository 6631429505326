'use client';

import { useEffect, useState } from 'react';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import Snackbar from '@/components/Snackbar';
import { useConfig } from '@/app/contexts/ConfigContext';

export default function LoginSuccess() {
  const { wording, config } = useConfig();

  const params = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();

  const isLoginSuccessful = params.get('login') === 'true';
  const [snackbarVisible, setSnackbarVisible] = useState(false);

  useEffect(() => {
    if (isLoginSuccessful) {
      setSnackbarVisible(true);
      setTimeout(() => {
        setSnackbarVisible(false);
      }, 5000);
      router.replace(pathname);
    }
  }, [isLoginSuccessful, pathname, router]);

  return (
    <Snackbar
      show={snackbarVisible}
      message={wording!.components_login_success_text}
      onClose={() => setSnackbarVisible(false)}
      bgColor={config.theme.accentColorOne}
      textColor={config.theme.textColorDark}
    />
  );
}
