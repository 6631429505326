interface SnackbarProps {
  message: string;
  show: boolean;
  onClose: () => void;
  bgColor: string;
  textColor: string;
}

const Snackbar = ({
  message,
  show,
  onClose,
  bgColor,
  textColor,
}: SnackbarProps) => {
  return (
    <div
      className={`fixed top-4 right-4 transition transform ${
        show ? 'translate-y-0 opacity-100' : '-translate-y-4 opacity-0'
      } text-zinc-800 bg-primary font-semibold text-lg px-6 rounded shadow-lg`}
      style={{
        transitionDuration: '300ms',
        backgroundColor: bgColor,
        color: textColor,
        zIndex: 10001,
      }}
    >
      {show && (
        <div className='flex items-center'>
          <span>{message}</span>
          <button className='py-4 ml-4' onClick={onClose}>
            <img src='/close.svg' alt='a closing X' />
          </button>
        </div>
      )}
    </div>
  );
};

export default Snackbar;
